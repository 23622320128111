@font-face {
  font-family: 'Campton-Light';
  src:
    local('Campton-Light'),
    url('./assets/fonts/Campton-Light/font.woff') format('woff'),
    url('./assets/fonts/Campton-Light/font.woff2') format('woff2');
}

@font-face {
  font-family: 'Campton-Medium';
  src:
    local('Campton-Medium'),
    url('./assets/fonts/Campton-Medium/font.woff') format('woff'),
    url('./assets/fonts/Campton-Medium/font.woff2') format('woff2');
}

/* GILROY */
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src:
    local('Gilroy-ExtraBold'),
    url('./assets/fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
    url('./assets/fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2');
}

#root {
  font-family: 'Campton-Medium', Verdana, sans-serif;
  background: #f6f6f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html,
body {
  margin: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: 'Gilroy-ExtraBold', Verdana, sans-serif;
}

/* Ant design */
.ant-btn {
  transition: 0.2s ease-in-out;
  text-shadow: none !important;
  box-shadow: none !important;
  font-size: 14px;
  border-width: 1px;
  border-radius: 20px !important;
}
label,
input,
.ant-menu,
.ant-drawer,
.ant-select-dropdown,
.ant-dropdown-menu,
.ant-modal,
.ant-select,
.ant-picker-dropdown,
.ant-message-custom-content,
.ant-notification {
  font-family: 'Campton-Medium', Verdana, sans-serif;
}
/* fixes password icon overlap with 1password */
.ant-input-password {
  padding-right: 30px !important;
}
.ant-notification-notice {
  border-radius: 10px !important;
}
.ant-notification-notice-description {
  color: #777e90;
}
.ant-empty-description {
  color: #999;
}
button {
  transition: 0.2s ease-in-out;
}

/* Bypass buggy react error overlay which appears with lint errors */
/* https://stackoverflow.com/questions/70499543/create-react-app-creates-this-iframe-that-prevents-me-from-clicking-or-editing */
body > iframe {
  pointer-events: none;
}
